import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { routes } from "../config/routes";

export default function Home() {
    return (
        <div className="flex flex-col items-center">
            <Link to={routes.tripRequests} className="bg-blue-800 text-white p-5 m-3 flex rounded-lg">
                Trip Requests
                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            </Link>
            <Link to={routes.captainRequests} className="bg-blue-800 text-white p-5 m-3 flex rounded-lg">
                Captain Requests
                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            </Link>
            <Link to={routes.cancelRequests} className="bg-blue-800 text-white p-5 m-3 flex rounded-lg">
                Cancel Requests
                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            </Link>
            <Link to={routes.questions} className="bg-blue-800 text-white p-5 m-3 flex rounded-lg">
                Trip Questions
                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            </Link>
            <Link to={routes.payouts} className="bg-blue-800 text-white p-5 m-3 flex rounded-lg">
                Payouts
                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            </Link>
        </div>
    )
}