import { Link } from "react-router-dom"
import { routes } from "../config/routes"
import useApi from "../hooks/use-api"
import { useEffect, useState } from "react"
import { Api } from "../config/api-endpoints"


export default function TripRequests() {
    const {api} = useApi()
    const [trips,setTrips] = useState({
        data: [],
        loading: true,
    })
    async function loadPayouts(){
        const res = await api.get(Api.getTripRequests)
        console.log(res.data)
        if(res.status==200){
            setTrips({
                data:res.data,
                loading:false
            })
        }
        else{
            setTrips((prev)=>(
                {...prev,
                    loading:false
                }
            ))
        }
    }
    useEffect(()=>{
        loadPayouts()
    },[])
    return (
        <>
        {trips.loading?<div>loading...</div>:
        <div>
            {trips.data.map((trip: any) =>
                <Link to={routes.tripRequest(trip.id)} key={trip.id} className="flex items-center justify-center gap-10 p-4 m-2 shadow-md">
                    <div>{new Date(trip.departure_date).toDateString()}</div>
                    <div className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[50%]">{trip.title}</div>
                    <button className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white m-1 p-2 rounded-md">View</button>
                </Link>
            )}
        </div>
        }
        </>
    )
}