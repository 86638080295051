import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import Button from './ui/button'
import { useModal } from './modals/context'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../config/routes'
import useApi from '../hooks/use-api'
import { Api } from '../config/api-endpoints'

export default function CaptainData() {
    const navigate = useNavigate()
    const { setModal } = useModal()
    const { username } = useParams()
    const { api } = useApi()
    const [state, setState] = useState({ approving: false })
    const [captain, setCaptain] = useState<any>({
        data: [],
        loading: true
    })

    async function approveCaptain() {
        const confirm = window.confirm('Are you sure you want to approve this captain?')
        if (!confirm) return

        setState({ approving: true })
        const res = await api.post(Api.approveCaptain(username!))
        if (res.status==200) {
            navigate(routes.captainRequests)
            toast.success('Captain approved successfully')
        } else {
            setState({ approving: false })
        }
    }

    async function loadCaptain() {
        const res = await api.get(Api.getCaptainData(username!))
        if (res.status == 200) {
            console.log(res.data)
            setCaptain({
                data: res.data,
                loading: false
            })
        } else {
            setCaptain((prev: any) => ({
                ...prev,
                loading: false
            }))
        }
    }

    useEffect(() => {
        loadCaptain()
    }, [])
    return (
        <>
            {captain.loading ? <div>loading...</div> :
                <div className="mb-44">
                    <h2 className="text-2xl font-semibold my-5 text-center">Captain Request Data:</h2>
                    <div className="flex mt-5 gap-5">
                        <img src={captain.data.captain_data.id_card} className="rounded-md w-1/2 object-contain" />
                        <div>
                            <div className="h-1/2">
                                <h3 className="text-xl font-semibold">Experience</h3>
                                <p>{captain.data.captain_data.experience}</p>
                            </div>
                            <div className="h-1/2">
                                <h3 className="text-xl font-semibold">Skills</h3>
                                <p>{captain.data.captain_data.skills}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center gap-7 my-16">
                        <Button variant="solid" isLoading={state.approving} className="bg-green-500 text-white"
                            onClick={approveCaptain}
                        >Approve</Button>
                        <Button variant="solid" className="bg-red-500 text-white"
                            onClick={() => setModal({ view: 'REJECT_CAPTAIN_REQUEST', open: true, user: captain.data })}
                        >Reject</Button>
                    </div>
                    <div className="flex justify-center gap-5">
                        <div>Email:
                            <a href={`mailto:${captain.data.email}`} className="text-blue-500 hover:underline">{captain.data.email}</a>
                        </div>
                        <div>Phone:
                            <a href={`tel:${captain.data.phone}`} className="text-blue-500 hover:underline">{captain.data.phone}</a>
                        </div>
                    </div>

                    <div>
                        <img src={captain.data.avatar} className="w-44 h-44 rounded-full mx-auto mt-3" />
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/user/${captain.data.username}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Profile
                                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-blue-500" />
                            </a>
                        </div>
                        <table className="mx-auto mt-2">
                            <tr>
                                <td className="font-semibold">First Name:</td>
                                <td>{captain.data.first_name}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Last Name:</td>
                                <td>{captain.data.last_name}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Username:</td>
                                <td>{captain.data.username}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Bio:</td>
                                <td>{captain.data.bio}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Gender:</td>
                                <td>{captain.data.gender}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Birth Date:</td>
                                <td>{new Date(captain.data.birth_date).toDateString()}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Languages:</td>
                                <td>{captain.data.languages.join(', ')}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Country:</td>
                                <td>{captain.data.country}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">State:</td>
                                <td>{captain.data.state}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">City:</td>
                                <td>{captain.data.city}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Town:</td>
                                <td>{captain.data.town}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Zip Code:</td>
                                <td>{captain.data.zip_code}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Street Address:</td>
                                <td>{captain.data.street_address}</td>
                            </tr>

                        </table>
                    </div>

                </div>
            }</>
    )
}