import { Link } from "react-router-dom"
import { routes } from "../config/routes"
import useApi from "../hooks/use-api"
import { useEffect, useState } from "react"
import { Api } from "../config/api-endpoints"

function CancelRequest({ tripuser }: { tripuser: any }) {
    return (
        <Link to={routes.cancelRequest(tripuser.id)} className="flex items-center justify-center gap-10 p-4 m-2 shadow-md">
            <div className="flex gap-2 items-center">
                <img src={tripuser.User.avatar} className="w-10 h-10 rounded-full" alt="avatar" />
                {`${tripuser.User.first_name} ${tripuser.User.last_name}`}
            </div>
            <div>@{tripuser.User.username}</div>
            <button className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white m-1 p-2 rounded-md">View</button>
        </Link>
    )
}

export default function CancelRequests() {
    const {api} = useApi()
    const [requests,setRequests] = useState({
        data: [],
        loading: true,
    })
    async function loadData(){
        const res = await api.get(Api.getCancelRequests)
        if(res.status==200){
            console.log(res.data)
            setRequests({
                data:res.data,
                loading:false
            })
        }
        else{
            setRequests((prev)=>(
                {...prev,
                    loading:false
                }
            ))
        }
    }
    useEffect(()=>{
        loadData()
    },[])
    return (
        <>
        {requests.loading?<div>loading...</div>:
        <div>
            {requests.data.map((tripuser: any) => <CancelRequest key={tripuser.id} tripuser={tripuser} />)}
        </div>
        }
        </>
    )
}