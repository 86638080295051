'use client';

import { atom, useSetAtom, useAtomValue } from 'jotai';

export type MODAL_VIEW =
  | 'REJECT_TRIP_REQUEST'
  | 'REJECT_CAPTAIN_REQUEST'
  | 'SUBMIT_PAYOUT'
  | 'REJECT_CANCEL_REQUEST'
  | 'APPROVE_CANCEL_REQUEST'
  | 'LOG_IN'

type ModalPropsType ={
  open: boolean;
  view: MODAL_VIEW;
  user?: any,
  trip?: any,
  tripuser?: any,
}

export const modalAtom = atom<ModalPropsType>({
  open: false,
  view: 'LOG_IN',
});

export function useModal() {
  let modal = useAtomValue(modalAtom);
  let setModal = useSetAtom(modalAtom);

  function openModal(view: MODAL_VIEW) {
    setModal({
      ...modal,
      view,
      open: true,
    });
  }

  function closeModal() {
    setModal({
      ...modal,
      open: false,
    });
  }

  return {
    ...modal,
    setModal,
    openModal,
    closeModal,
  };
}
