import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Home from "./components/home";
import CaptainRequests from "./components/captain-requests";
import CaptainRequest from "./components/captain-request";
import Questions from "./components/questions";
import Question from "./components/question";
import ModalContainer from "./components/modals/view";
import "react-toastify/dist/ReactToastify.css";
import { Bounce, ToastContainer } from "react-toastify";
import Payouts from "./components/payouts";
import Payout from "./components/payout";
import {routes} from "./config/routes";
import CancelRequests from "./components/cancel-requests";
import CancelRequest from "./components/cancel-request";
import TripRequests from "./components/trip-requests";
import TripRequest from "./components/trip-request";
import { useEffect } from "react";
import { useModal } from "./components/modals/context";

function App() {
  const {setModal} = useModal();
  useEffect(()=>{
    if(!localStorage.getItem('password')){
      setModal({open:true,view:'LOG_IN'})
    }
  },[])

  return (
    <BrowserRouter>
      <Header />
      <div className="md:w-[768px] lg:w-[1024px] m-auto p-2">
        <Routes>
          <Route path={routes.home} element={<Home/>} />
          <Route path={routes.tripRequests} element={<TripRequests/>} />
          <Route path={routes.tripRequest(':tripId')} element={<TripRequest/>}/>
          <Route path={routes.captainRequests} element={<CaptainRequests />} />
          <Route path={routes.captainRequest(':username')} element={<CaptainRequest/>} />
          <Route path={routes.cancelRequests} element={<CancelRequests />} />
          <Route path={routes.cancelRequest(':tripuserId')} element={<CancelRequest/>} />
          <Route path={routes.questions} element={<Questions/>} />
          <Route path={routes.question(':questionId')} element={<Question/>} />
          <Route path={routes.payouts} element={<Payouts/>} />
          <Route path={routes.payout(':tripId')} element={<Payout/>} />
        </Routes>
      </div>
      <ModalContainer/>
      <ToastContainer
          limit={3}
          className={'!z-[99999]'}
          position="top-center"
          autoClose={5000}
          newestOnTop
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
    </BrowserRouter>
  );
}

export default App;
