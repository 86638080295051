import { Link } from "react-router-dom"
import { routes } from "../config/routes"
import useApi from "../hooks/use-api"
import { useEffect, useState } from "react"
import { Api } from "../config/api-endpoints"

function CaptainRequest({ user }: { user: any }) {
    return (
        <Link to={routes.captainRequest(user.username)} className="flex items-center justify-center gap-10 p-4 m-2 shadow-md">
            <div className="flex gap-2 items-center">
                <img src={user.avatar} className="w-10 h-10 rounded-full" alt="avatar" />
                {`${user.first_name} ${user.last_name}`}
            </div>
            <div>@{user.username}</div>
            <button className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white m-1 p-2 rounded-md">View</button>
        </Link>
    )
}

export default function CaptainRequests() {
    const {api} = useApi()
    const [users,setUsers] = useState({
        data: [],
        loading: true,
    })
    async function loadPayouts(){
        const res = await api.get(Api.getCaptainRequests)
        if(res.status==200){
            setUsers({
                data:res.data,
                loading:false
            })
        }
        else{
            setUsers((prev)=>(
                {...prev,
                    loading:false
                }
            ))
        }
    }
    useEffect(()=>{
        loadPayouts()
    },[])
    return (
        <>
        {users.loading?<div>loading...</div>:
        <div>
            {users.data.map((user: any) => <CaptainRequest key={user.id} user={user} />)}
        </div>
        }
        </>
    )
}