import { Link } from "react-router-dom"
import { routes } from "../config/routes"
import { useEffect, useState } from "react"
import useApi from "../hooks/use-api"
import { Api } from "../config/api-endpoints"

export default function Payouts(){
    const {api} = useApi()
    const [payouts,setPayouts] = useState({
        data: [],
        loading: true,
    })
    async function loadPayouts(){
        const res = await api.get(Api.getPayouts)
        if(res.status==200){
            console.log(res.data)
            setPayouts({
                data:res.data,
                loading:false
            })
        }
        else{
            setPayouts((prev)=>(
                {...prev,
                    loading:false
                }
            ))
        }
    }
    useEffect(()=>{
        loadPayouts()
    },[])
    return (
        <>
        {payouts.loading?<div>loading...</div>:
        <div>
            {payouts.data.map((payout: any) =>
                <Link to={routes.payout(payout.id)} key={payout.id} className="flex items-center justify-center gap-10 p-4 m-2 shadow-md">
                    <div>{new Date(payout.departure_date).toDateString()}</div>
                    <div className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[50%]">{payout.title}</div>
                    <button className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white m-1 p-2 rounded-md">View</button>
                </Link>
            )}
        </div>
        }</>
    )
}