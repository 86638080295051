'use client';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { z } from 'zod';
import Textarea from '../../ui/form-fields/textarea';
import { useModal } from '../context';
import ActionIcon from '../../ui/action-icon';
import Text from '../../ui/typography/text';
import Button from '../../ui/button';
// import useApi from '@/hooks/use-api';
// import { Api } from '@/config/api-endpoints';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/routes';
import useApi from '../../../hooks/use-api';
import { Api } from '../../../config/api-endpoints';
import Quantity from '../../ui/form-fields/quantity';
import Input from '../../ui/form-fields/input';

const AddReasonSchema = z.object({
  refund_percentage: z.coerce.number().int().min(0).max(100),
});

type AddReasonModalType = z.infer<typeof AddReasonSchema>;

export default function ApproveCancelRequest({ tripuser }: { tripuser?: any }) {
  const navigate = useNavigate()
  const { closeModal } = useModal();
  const { api } = useApi();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AddReasonModalType>({
    defaultValues: {
      refund_percentage: 100
    },
    resolver: zodResolver(AddReasonSchema),
  });

  async function handleSubmit2(data: any) {
    const res = await api.post(Api.cancelTrip(tripuser.id), data)
    console.log(res)
    if (res.status == 200) {
      closeModal()
      navigate(routes.cancelRequests)
      toast.success('Cancellation approved successfully')
    }
  }

  return (
    <div className="relative z-50 mx-auto w-full max-w-full overflow-hidden rounded-xl bg-white p-6 sm:w-[520px] sm:p-8 md:w-[648px] md:p-10 lg:p-12">
      <div className="flex items-center justify-between">
        <Text tag="h3" className="text-xl leading-8 md:!text-xl">
          Approve Cancellation
        </Text>
        <ActionIcon
          size="sm"
          variant="outline"
          className="border-none !p-0 focus:!ring-0"
          onClick={closeModal}
        >
          <XMarkIcon className="h-6 w-6" />
        </ActionIcon>
      </div>
      <form noValidate onSubmit={handleSubmit((data) => handleSubmit2(data))}>

        <div className='flex justify-between mt-5'>
          <Text tag='h3'>Trip Cost:</Text>
          <Input
            type="number"
            size='sm'
            value={tripuser.Trip.price.total}
            inputClassName='text-center'
            className='w-32'
            startIcon='₹'
            startIconClassName='text-gray-500 z-10'
            disabled
          />
        </div>

        <div className='flex justify-between mt-5'>
          <Text tag='h3'>Refund Percentage</Text>
          <Controller
            name="refund_percentage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Quantity
                label="%"
                value={value}
                onChange={onChange}
                error={errors.refund_percentage?.message}
              />
            )}
          />
        </div>

        <div className='flex justify-between mt-5'>
          <Text tag='h3'>Refund Amount:</Text>
          <Input
            type="number"
            size='sm'
            value={tripuser.Trip.price.total * watch('refund_percentage') / 100}
            inputClassName='text-center'
            className='w-32'
            startIcon='₹'
            startIconClassName='text-gray-500 z-10'
            disabled
          />
        </div>

        <Button
          type="submit"
          size="xl"
          variant="solid"
          className="mt-4 w-full !py-[15px] !font-semibold uppercase tracking-[0.7px] sm:mt-8 lg:mt-12"
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
