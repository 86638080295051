import { ArrowTopRightOnSquareIcon, ArrowUpRightIcon } from '@heroicons/react/24/solid'
import Button from './ui/button'
import { useModal } from './modals/context'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../config/routes'
import useApi from '../hooks/use-api'
import { Api } from '../config/api-endpoints'

export default function TripRequest() {
    const navigate = useNavigate()
    const { setModal } = useModal()
    const { tripId } = useParams()
    const { api } = useApi()
    const [state, setState] = useState({ approving: false })
    const [trip, setTrip] = useState<any>({
        data: [],
        loading: true
    })

    async function approve() {
        const confirm = window.confirm('Are you sure you want to approve this Trip?')
        if (!confirm) return

        setState({ approving: true })
        const res = await api.post(Api.approveTrip(tripId!))
        if (res.status==200) {
            navigate(routes.tripRequests)
            toast.success('Trip approved successfully')
        } else {
            setState({ approving: false })
        }
    }

    async function loadTrip() {
        const res = await api.get(Api.getTripRequest(tripId!))
        if (res.status == 200) {
            console.log(res.data)
            setTrip({
                data: res.data,
                loading: false
            })
        } else {
            setTrip((prev: any) => ({
                ...prev,
                loading: false
            }))
        }
    }

    useEffect(() => {
        loadTrip()
    }, [])
    return (
        <>
            {trip.loading ? <div>loading...</div> :
                <div className="mb-44">
                    {/* Captain Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">Captain Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/user/${trip.data.Captain.username}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Captain Profile
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <div className="flex justify-center gap-7">
                            <div>Phone:
                                <a href={`tel:${trip.data.Captain.phone}`} className="text-blue-500 hover:underline">{trip.data.Captain.phone}</a>
                            </div>
                            <div>Email:
                                <a href={`mailto:${trip.data.Captain.email}`} className="text-blue-500 hover:underline">{trip.data.Captain.email}</a>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center gap-7 my-16">
                        <Button variant="solid" isLoading={state.approving} className="bg-green-500 text-white"
                            onClick={approve}
                        >Approve</Button>
                        <Button variant="solid" className="bg-red-500 text-white"
                            onClick={() => setModal({ view: 'REJECT_TRIP_REQUEST', open: true, trip: trip.data })}
                        >Reject</Button>
                    </div>

                    {/* Trip Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">Trip Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/trip/${trip.data.id}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Trip
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <table className="table-auto mx-auto whitespace-break-spaces">
                            <tr>
                                <td className="font-semibold">Title:</td>
                                <td>{trip.data.title}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Departure Date:</td>
                                <td>{new Date(trip.data.departure_date).toDateString()}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Location</td>
                                <td>{trip.data.location.formatted_address}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Cancellation Policy</td>
                                <td>{trip.data.cancellation_policy}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Duration</td>
                                <td>{trip.data.duration} days</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Crisis Management Plan</td>
                                <td><a href={trip.data.crisis_management_plan} target='_blank' className='text-blue-600 hover:underline'>{trip.data.crisis_management_plan}</a></td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Number of tripmates</td>
                                <td><b>Min:</b>{trip.data.number_of_tripmates.min} <br/><b>Max:</b>{trip.data.number_of_tripmates.max}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Price</td>
                                <td>₹{trip.data.price}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Seo Description</td>
                                <td>{trip.data.seo_description}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Keywords</td>
                                <td>{trip.data.keywords.join(',')}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Categories</td>
                                <td>{trip.data.categories.join(',')}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Accommodation</td>
                                <td>{trip.data.accommodation.join(',')}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Included</td>
                                <td>{trip.data.included.join(',')}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Excluded</td>
                                <td>{trip.data.excluded.join(',')}</td>
                            </tr>
                            <tr>
                                <td className='font-semibold'>Whats_Special</td>
                                <td>{trip.data.whats_special.join(',')}</td>
                            </tr>
                        </table>
                    </div>

                </div>
            }</>
    )
}