'use client';

import { XMarkIcon } from '@heroicons/react/24/solid';
import { useModal } from '../context';
import ActionIcon from '../../ui/action-icon';
import Text from '../../ui/typography/text';
import Button from '../../ui/button';
import Input from '../../ui/form-fields/input';
import { useState } from 'react';

export default function LogIn() {
  const { closeModal } = useModal();
  const [password, setPassword] = useState('');

  async function OnSubmit() {
    localStorage.setItem('password', password);
    closeModal();
  }

  return (
    <div className="relative z-50 mx-auto w-full max-w-full overflow-hidden rounded-xl bg-white p-6 sm:w-[520px] sm:p-8 md:w-[648px] md:p-10 lg:p-12">
      <div className="flex items-center justify-between">
        <Text tag="h3" className="text-xl leading-8 md:!text-xl">
          Log In
        </Text>
        <ActionIcon
          size="sm"
          variant="outline"
          className="border-none !p-0 focus:!ring-0"
          onClick={closeModal}
        >
          <XMarkIcon className="h-6 w-6" />
        </ActionIcon>
      </div>
      <div className='mt-5'>
        <Input
            label="Password"
            placeholder="Enter your password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          onClick={OnSubmit}
          className='!block mt-4 mx-auto'
        >
            Submit
        </Button>
      </div>
    </div>
  );
}
