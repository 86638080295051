'use client';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { z } from 'zod';
import Textarea from '../../ui/form-fields/textarea';
import { useModal } from '../context';
import ActionIcon from '../../ui/action-icon';
import Text from '../../ui/typography/text';
import Button from '../../ui/button';
// import useApi from '@/hooks/use-api';
// import { Api } from '@/config/api-endpoints';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/routes';
import useApi from '../../../hooks/use-api';
import { Api } from '../../../config/api-endpoints';

const AddReasonSchema = z.object({
  reason: z.string().min(1, { message: 'Reason cant be an empty string' }),
});

type AddReasonModalType = z.infer<typeof AddReasonSchema>;

export default function RejectTripRequest({ trip }: { trip?: any}) {
  const navigate = useNavigate()
  const { closeModal } = useModal();
  const {api} = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AddReasonModalType>({
    resolver: zodResolver(AddReasonSchema),
  });

  async function handleQuestion(data: any) {
    const res = await api.post(Api.rejectTrip(trip.id), data)
    if(res.status==200){
      closeModal()
      navigate(routes.tripRequests)
      toast.success('Trip Request rejected successfully')
    }
  }

  return (
    <div className="relative z-50 mx-auto w-full max-w-full overflow-hidden rounded-xl bg-white p-6 sm:w-[520px] sm:p-8 md:w-[648px] md:p-10 lg:p-12">
      <div className="flex items-center justify-between">
        <Text tag="h3" className="text-xl leading-8 md:!text-xl">
          Reason
        </Text>
        <ActionIcon
          size="sm"
          variant="outline"
          className="border-none !p-0 focus:!ring-0"
          onClick={closeModal}
        >
          <XMarkIcon className="h-6 w-6" />
        </ActionIcon>
      </div>
      <form noValidate onSubmit={handleSubmit((data) => handleQuestion(data))}>
        <Textarea
          className="mt-8"
          label="This will be sent to the captain as notification"
          textareaClassName="w-full min-h-[160px] focus:border-gray-dark py-3 !px-5"
          labelClassName="!text-base font-bold text-gray-dark"
          errorClassName='!text-red-500'
          {...register('reason')}
          error={errors?.reason?.message}
        />
        <Button
          type="submit"
          size="xl"
          variant="solid"
          className="mt-4 w-full !py-[15px] !font-semibold uppercase tracking-[0.7px] sm:mt-8 lg:mt-12"
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
