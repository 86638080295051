export const routes = {
    home: '/',
    tripRequests: '/trip-requests',
    tripRequest: (tripId:string)=>`/trip-request/${tripId}`,
    captainRequests: '/captain-requests',
    captainRequest: (username:string)=>`/captain-request/${username}`,
    cancelRequests: '/cancel-requests',
    cancelRequest: (tripUserId:string)=>`/cancel-request/${tripUserId}`,
    questions: '/trip-questions',
    question: (questionId:string)=>`/trip-question/${questionId}`,
    payouts: '/payouts',
    payout: (tripId:string)=>`/payout/${tripId}`
}