import { ArrowTopRightOnSquareIcon, ArrowUpRightIcon } from '@heroicons/react/24/solid'
import Button from './ui/button'
import { useModal } from './modals/context'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../config/routes'
import useApi from '../hooks/use-api'
import { Api } from '../config/api-endpoints'

export default function CancelRequest() {
    const { setModal } = useModal()
    const { tripuserId } = useParams()
    const { api } = useApi()
    const [request, setRequest] = useState<any>({
        data: [],
        loading: true
    })

    async function loadData() {
        const res = await api.get(Api.getCancelRequest(tripuserId!))
        if (res.status == 200) {
            console.log(res.data)
            setRequest({
                data: res.data,
                loading: false
            })
        } else {
            setRequest((prev: any) => ({
                ...prev,
                loading: false
            }))
        }
    }

    useEffect(() => {
        loadData()
    }, [])
    return (
        <>
            {request.loading ? <div>loading...</div> :
                <div className="mb-44">
                    <h2 className="text-2xl font-semibold my-5 text-center">Cancellation Request:</h2>

                    <div className='flex flex-col items-center'>
                        <div>
                            <p><b>Reason : </b>{request.data.payment.reason}</p>
                            <p><b>Feedback : </b>{request.data.payment.feedback}</p>
                        </div>
                    </div>
                    <h2 className="text-2xl font-semibold my-5 text-center">Cancellation Policy:</h2>

                    <p className='whitespace-break-spaces text-center'>{request.data.Trip.cancellation_policy}</p>

                    <h2 className="text-2xl font-semibold my-5 text-center">Cancelled At:</h2>
                    <p className='whitespace-break-spaces text-center'>{new Date(request.data.payment.cancelledAt).toDateString()}</p>

                    

                    <div className="flex justify-center gap-7 my-16">
                        <Button variant="solid" className="bg-green-500 text-white"
                            onClick={() => setModal({ view: 'APPROVE_CANCEL_REQUEST', open: true, tripuser: request.data })}
                        >Approve Cancellation</Button>
                        <Button variant="solid" className="bg-red-500 text-white"
                            onClick={() => setModal({ view: 'REJECT_CANCEL_REQUEST', open: true, tripuser: request.data })}
                        >Reject</Button>
                    </div>

                    {/* Captain Info */}
                    <div className="mt-5">
                        <div className="text-center text-xl font-semibold">Captain Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/user/${request.data.Trip.Captain.username}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Captain Profile
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <div className="flex justify-center gap-7">
                            <div>Phone:
                                <a href={`tel:${request.data.Trip.Captain.phone}`} className="text-blue-500 hover:underline">{request.data.Trip.Captain.phone}</a>
                            </div>
                            <div>Email:
                                <a href={`mailto:${request.data.Trip.Captain.email}`} className="text-blue-500 hover:underline">{request.data.Trip.Captain.email}</a>
                            </div>
                        </div>
                    </div>

                    {/* User Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">User Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/user/${request.data.User.username}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View User Profile
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <div className="flex justify-center gap-7">
                            <div>Phone:
                                <a href={`tel:${request.data.User.phone}`} className="text-blue-500 hover:underline">{request.data.User.phone}</a>
                            </div>
                            <div>Email:
                                <a href={`mailto:${request.data.User.email}`} className="text-blue-500 hover:underline">{request.data.User.email}</a>
                            </div>
                        </div>
                    </div>

                    {/* Trip Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">Trip Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/trip/${request.data.Trip.id}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Trip
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <table className="table-auto mx-auto">
                            <tr>
                                <td className="font-semibold">Title:</td>
                                <td>{request.data.Trip.title}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Departure Date:</td>
                                <td>{new Date(request.data.Trip.departure_date).toDateString()}</td>
                            </tr>
                        </table>
                    </div>


                </div>
            }</>
    )
}