import { ArrowUpRightIcon } from "@heroicons/react/24/solid"
import Button from "./ui/button"
import Textarea from "./ui/form-fields/textarea"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { routes } from "../config/routes"
import { toast } from "react-toastify"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import useApi from "../hooks/use-api"
import { Api } from "../config/api-endpoints"

const AnswerSchema = z.object({
    answer: z.string().min(1, { message: 'Answer cant be an empty string' }),
});
type AnswerModalType = z.infer<typeof AnswerSchema>;

export default function Question() {
    const { questionId } = useParams()
    const { api } = useApi()
    const navigate = useNavigate()
    const [question, setQuestion] = useState<any>({
        data: [],
        loading: true
    })

    async function loadQuestion() {
        const res = await api.get(Api.getTripQuestion(questionId!))
        if (res.status == 200) {
            console.log(res.data)
            setQuestion({
                data: res.data,
                loading: false
            })
        } else {
            setQuestion((prev: any) => ({
                ...prev,
                loading: false
            }))
        }
    }

    useEffect(() => {
        loadQuestion()
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<AnswerModalType>({
        resolver: zodResolver(AnswerSchema),
    });

    async function handleAnswer(data: AnswerModalType) {
        const res = await api.post(Api.answerTripQuestion(questionId!),data)
        if (res.status==200) {
            navigate(routes.questions)
            toast.success('Answer submitted successfully')
        }
    }
    return (
        <>
            {question.loading ? <div>loading...</div> :
                <div className='py-5 md:py-6'>
                    <div className="flex">
                        <img
                            src={question.data.Querier?.avatar}
                            alt={question.data.Querier?.first_name}
                            width={32}
                            height={32}
                            className='rounded-full w-8 h-8'
                        />
                        <p className="ml-3 font-bold tracking-wide text-sm text-secondary">
                            {question.data.question}
                        </p>
                    </div>
                    <div className="text-sm italic mt-1">Asked at: {new Date(question.data.createdAt).toDateString()}</div>

                    <form noValidate onSubmit={handleSubmit((data) => handleAnswer(data))}>

                        <Textarea
                            className="mt-5"
                            label="Answer :"
                            textareaClassName="w-full min-h-[160px] focus:border-gray-dark py-3 !px-5"
                            labelClassName="font-bold"
                            errorClassName='!text-red-500'
                            {...register('answer')}
                            error={errors?.answer?.message}
                        />

                        <div className="flex justify-end mt-2 gap-2">
                            <Button type="submit" variant="solid" isLoading={isSubmitting}>Submit</Button>
                        </div>
                    </form>

                    {/* Captain Info */}
                    <div className="mt-5">
                        <div className="text-center text-xl font-semibold">Captain Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/user/${question.data.Trip.Captain.username}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Captain Profile
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <div className="flex justify-center gap-7">
                            <div>Phone:
                                <a href={`tel:${question.data.Trip.Captain.phone}`} className="text-blue-500 hover:underline">{question.data.Trip.Captain.phone}</a>
                            </div>
                            <div>Email:
                                <a href={`mailto:${question.data.Trip.Captain.email}`} className="text-blue-500 hover:underline">{question.data.Trip.Captain.email}</a>
                            </div>
                        </div>
                    </div>

                    {/* Querier Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">Querier Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/user/${question.data.Querier?.username}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Querier Profile
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <div className="flex justify-center gap-7">
                            <div>Phone:
                                <a href={`tel:${question.data.Querier?.phone}`} className="text-blue-500 hover:underline">{question.data.Querier?.phone}</a>
                            </div>
                            <div>Email:
                                <a href={`mailto:${question.data.Querier?.email}`} className="text-blue-500 hover:underline">{question.data.Querier?.email}</a>
                            </div>
                        </div>
                    </div>

                    {/* Trip Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">Trip Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/trip/${question.data.Trip.id}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Trip
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <table className="table-auto mx-auto">
                            <tr>
                                <td className="font-semibold">Title:</td>
                                <td>{question.data.Trip.title}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Departure Date:</td>
                                <td>{new Date(question.data.Trip.departure_date).toDateString()}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            }</>
    )
}