import axios from 'axios';
import { toast } from 'react-toastify';
import { useModal } from '../components/modals/context';

const useApi = () => {
  const {openModal} = useModal();
  const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    timeout: 20000,
    headers: {
      'Content-Type': 'application/json',
      'password': localStorage.getItem('password')
    }
  });

  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();

  api.interceptors.request.use((config) => {
    config.cancelToken = source.token;
    return config;
  });

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
        return {status:444}
      }
      else {
        console.error(error);
        if (error.response?.data?.authRequired) {
          openModal('LOG_IN');
        }
        if (error.response?.data?.error) toast.error(error.response?.data?.error);
        else if(error.response?.data?.warning) toast.warning(error.response?.data?.warning);
        else if(error.response?.data?.info) toast.info(error.response?.data?.info);
        else toast.error(error.response?.data?.message || error.message);
      }

      return error.response || { status: 444 }
    }
  );

  const cancelRequest = () => {
    source.cancel('Request canceled by user');
  };

  return { api, cancelRequest };
};

export default useApi;
