import { Link } from "react-router-dom"
import { routes } from "../config/routes"
import { useEffect, useState } from "react"
import useApi from "../hooks/use-api"
import { Api } from "../config/api-endpoints"

export default function Questions() {
    const {api} = useApi()
    const [questions,setQuestions] = useState({
        data: [],
        loading: true,
    })
    async function loadQuestions(){
        const res = await api.get(Api.getTripQuestions)
        if(res.status==200){
            setQuestions({
                data:res.data,
                loading:false
            })
        }
        else{
            setQuestions((prev)=>(
                {...prev,
                    loading:false
                }
            ))
        }
    }
    useEffect(()=>{
        loadQuestions()
    },[])
    return (
        <>
        {questions.loading?<div>loading...</div>:
        <div>
            {questions.data.map((question: any) =>
                <Link to={routes.question(question.id)} key={question.id} className="flex items-center justify-center gap-10 p-4 m-2 shadow-md">
                    <div className="flex gap-2 items-center">
                        <img src={question.Querier?.avatar} className="w-10 h-10 rounded-full" alt="avatar" />
                        {`${question.Querier?.first_name} ${question.Querier?.last_name}`}
                    </div>
                    <div className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[50%]">{question.question}</div>
                    <button className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white m-1 p-2 rounded-md">View</button>
                </Link>
            )}
        </div>
        }</>
    )
}