'use client';

import clsx from 'clsx';
import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useModal, MODAL_VIEW } from './context';
import Button from '../ui/button';
import RejectCaptainRequest from './views/reject-captain-request';
import SubmitPayout from './views/submit-payout';
import RejectCancelRequest from './views/reject-cancel-request';
import ApproveCancelRequest from './views/approve-cancel-request';
import RejectTripRequest from './views/reject-trip-request';
import LogIn from './views/log-in';


function renderModalContent(view: MODAL_VIEW | string, user:any, trip:any, tripuser:any) {
  switch (view) {
    case 'REJECT_TRIP_REQUEST':
      return <RejectTripRequest trip={trip} />;
    case 'REJECT_CAPTAIN_REQUEST':
      return <RejectCaptainRequest user={user} />;
    case 'SUBMIT_PAYOUT':
      return <SubmitPayout trip={trip} />;
    case 'REJECT_CANCEL_REQUEST':
      return <RejectCancelRequest tripuser={tripuser} />;
    case 'APPROVE_CANCEL_REQUEST':
      return <ApproveCancelRequest tripuser={tripuser} />;
    case 'LOG_IN':
      return <LogIn/>;
    default:
      return null;
  }
}

export default function ModalContainer() {
  const { open, view,user,trip,tripuser, closeModal } = useModal();
  
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className={clsx(
          'fixed inset-0 z-[9999] h-full w-full overflow-y-auto overflow-x-hidden bg-black bg-opacity-40 p-4 text-center'
        )}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 z-[9999] cursor-pointer" />
        </Transition.Child>

        {/* This element is need to fix FocusTap headless-ui warning issue */}
        <div className="sr-only">
          <Button
            size="sm"
            onClick={closeModal}
            className="opacity-50 hover:opacity-80 bg-black"
          >
            <span></span>
          </Button>
        </div>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-105"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-105"
        >
          <div className="relative z-[9999] inline-block w-full text-left align-middle sm:w-auto">
            {view && renderModalContent(view, user, trip, tripuser)}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
