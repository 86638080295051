export const Api = {
    getTripRequests: '/api/admin/triprequests',
    getTripRequest: (tripId:string)=>`/api/admin/triprequest/${tripId}`,
    approveTrip: (tripId:string)=>`/api/admin/approvetrip/${tripId}`,
    rejectTrip: (tripId:string)=>`/api/admin/rejecttrip/${tripId}`,

    getCaptainRequests: '/api/admin/captainrequests',
    getCaptainData: (username:string)=>`/api/admin/captaindata/${username}`,
    approveCaptain: (username:string)=>`/api/admin/approvecaptain/${username}`,
    rejectCaptain: (username:string)=>`/api/admin/rejectcaptain/${username}`,

    getCancelRequests: '/api/admin/cancelrequests',
    getCancelRequest: (tripUserId:string)=>`/api/admin/cancelrequest/${tripUserId}`,
    cancelTrip: (tripUserId:string)=>`/api/admin/canceltrip/${tripUserId}`,
    rejectCancel: (tripUserId:string)=>`/api/admin/rejectcancel/${tripUserId}`,
    
    getTripQuestions: '/api/admin/questions',
    getTripQuestion: (questionId:string)=>`/api/admin/question/${questionId}`,
    answerTripQuestion: (questionId:string)=>`/api/admin/answer/${questionId}`,
    
    getPayouts: '/api/admin/payouts',
    getPayout: (tripId:string)=>`/api/admin/payout/${tripId}`,
    submitPayout: (tripId:string)=>`/api/admin/submitpayout/${tripId}`,
}