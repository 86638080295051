'use client';

import clsx from 'clsx';
import { forwardRef } from 'react';
import FieldError from './field-error';
import FieldHelperText from './field-helper-text';
import { MinusIcon, PlusIcon, SunIcon } from '@heroicons/react/24/outline';
import Button from '../button';

const containerClasses = {
  base: 'flex flex-col',
};

const labelClasses = {
  size: {
    sm: 'text-xs mb-1',
    DEFAULT: 'text-sm mb-1.5',
    lg: 'text-sm mb-2',
    xl: 'text-base mb-2',
  },
};

const inputClasses = {
  base: 'block text-center peer w-full bg-transparent font-normal focus:outline-none focus:ring-[1px] transition duration-200 disabled:bg-gray-100 disabled:placeholder:text-gray-400 disabled:cursor-not-allowed disabled:border-gray-200',
  error:
    'border-red not-read-only:hover:enabled:!border-red not-read-only:focus:enabled:!border-red not-read-only:focus:!ring-red/30',
  size: {
    sm: 'pb-6 px-2.5 pt-1 text-xs h-8 leading-[32px]',
    DEFAULT:
      'pb-6 px-4 pt-2 text-sm h-10 lg:h-11 2xl:h-12 leading-[40px] lg:leading-[44px] 2xl:leading-[48px]',
    lg: 'pb-6 px-5 pt-2 text-base h-12 leading-[48px]',
    xl: 'pb-6 px-6 pt-2.5 text-base h-14 leading-[56px]',
  },
  rounded: {
    none: 'rounded-none',
    sm: 'rounded-sm',
    DEFAULT: 'rounded-md',
    lg: 'rounded-lg',
    pill: 'rounded-full',
  },
  variant: {
    active: {
      base: 'border bg-gray-0 placeholder:opacity-80 read-only:focus:ring-0',
      color: {
        DEFAULT:
          'border-gray-900 focus:enabled:border-gray-1000 focus:ring-gray-900/20 text-gray-1000',
        primary:
          'border-primary focus:enabled:border-primary focus:ring-primary/30 text-primary-dark',
        secondary:
          'border-secondary focus:enabled:border-secondary focus:ring-secondary/30 text-secondary-dark',
        danger:
          'border-red focus:enabled:border-red focus:ring-red/30 text-red-dark',
        info: 'border-blue focus:enabled:border-blue focus:ring-blue/30 text-blue-dark',
        success:
          'border-green focus:enabled:border-green focus:ring-green/30 text-green-dark',
        warning:
          'border-orange focus:enabled:border-orange-dark/70 focus:ring-orange/30 text-orange-dark',
      },
    },
    flat: {
      base: 'border border-0 placeholder:opacity-90 read-only:focus:ring-0',
      color: {
        DEFAULT:
          'bg-gray-200/70 not-read-only:hover:enabled:bg-gray-200/90 focus:ring-gray-900/20 text-gray-1000 placeholder:text-gray-600',
        primary:
          'bg-primary-lighter/70 not-read-only:hover:enabled:bg-primary-lighter/90 focus:ring-primary/30 text-primary-dark',
        secondary:
          'bg-secondary-lighter/70 not-read-only:hover:enabled:bg-secondary-lighter/90 focus:ring-secondary/30 text-secondary-dark',
        danger:
          'bg-red-lighter/70 not-read-only:hover:enabled:bg-red-lighter/90 focus:ring-red/30 text-red-dark',
        info: 'bg-blue-lighter/70 not-read-only:hover:enabled:bg-blue-lighter/90 focus:ring-blue/30 text-blue-dark',
        success:
          'bg-green-lighter/70 not-read-only:hover:enabled:bg-green-lighter/90 focus:ring-green/30 text-green-dark',
        warning:
          'bg-orange-lighter/80 not-read-only:hover:enabled:bg-orange-lighter/90 focus:ring-orange/30 text-orange-dark',
      },
    },
    outline: {
      base: 'bg-transparent border border-gray-300 read-only:border-gray-300 read-only:focus:ring-0 placeholder:text-gray-500',
      color: {
        DEFAULT:
          'not-read-only:hover:enabled:border-gray-1000 focus:border-gray-1000 not-read-only:focus:enabled:border-gray-1000 focus:ring-gray-900/20',
        primary:
          'not-read-only:hover:enabled:border-primary not-read-only:focus:enabled:border-primary focus:ring-primary/30',
        secondary:
          'not-read-only:hover:enabled:border-secondary not-read-only:focus:enabled:border-secondary focus:ring-secondary/30',
        danger:
          'not-read-only:hover:enabled:border-red not-read-only:focus:enabled:border-red focus:ring-red/30',
        info: 'not-read-only:hover:enabled:border-blue not-read-only:focus:enabled:border-blue focus:ring-blue/30',
        success:
          'not-read-only:hover:enabled:border-green not-read-only:focus:enabled:border-green focus:ring-green/30',
        warning:
          'not-read-only:hover:enabled:border-orange not-read-only:focus:enabled:border-orange-dark/70 focus:ring-orange/30',
      },
    },
    text: {
      base: 'border-0 bg-transparent',
      color: {
        DEFAULT:
          'hover:text-gray-1000 focus:ring-gray-900/20 placeholder:text-gray-500',
        primary: 'hover:text-primary-dark focus:ring-primary/30 text-primary',
        secondary:
          'hover:text-secondary-dark focus:ring-secondary/30 text-secondary',
        danger: 'hover:text-red-600 focus:ring-red/30 text-red',
        info: 'hover:text-blue-dark focus:ring-blue/30 text-blue',
        success:
          'hover:text-green-dark focus:ring-green/30 text-green placeholder:opacity-100',
        warning:
          'hover:text-orange-dark focus:ring-orange/30 text-orange placeholder:opacity-100',
      },
    },
  },
};

// apply only when input has clearable input
const inputClearableClasses = {
  base: 'rtl:pr-[inherit]',
  size: {
    sm: 'pr-8 rtl:pl-8',
    DEFAULT: 'pr-10 rtl:pl-10',
    lg: 'pr-11 rtl:pl-11',
    xl: 'pr-12 rtl:pl-12',
  },
  hasEndIcon: {
    sm: 'pr-14 rtl:pl-14',
    DEFAULT: 'pr-16 rtl:pl-16',
    lg: 'pr-[4.5rem] rtl:pl-[4.5rem]',
    xl: 'pr-20 rtl:pl-20',
  },
};

const buttonClasses = {
  base: 'bg-black p-2 text-white',
  size: {
    sm: 'px-2.5 py-1 text-xs h-8 leading-[32px]',
    DEFAULT:
      'px-4 py-2 text-sm h-10 lg:h-11 2xl:h-12 leading-[40px] lg:leading-[44px] 2xl:leading-[48px]',
    lg: 'px-5 py-2 text-base h-12 leading-[48px]',
    xl: 'px-6 py-2.5 text-base h-14 leading-[56px]',
  },
  rounded_l: {
    none: 'rounded-none',
    sm: 'rounded-l-sm',
    DEFAULT: 'rounded-l-md',
    lg: 'rounded-l-lg',
    pill: 'rounded-l-full',
  },
  rounded_r: {
    none: 'rounded-none',
    sm: 'rounded-r-sm',
    DEFAULT: 'rounded-r-md',
    lg: 'rounded-r-lg',
    pill: 'rounded-r-full',
  }
}

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  value: number;
  onChange: any
  variant?: keyof typeof inputClasses.variant;
  size?: keyof typeof inputClasses.size;
  rounded?: keyof typeof inputClasses.rounded;
  color?: keyof (typeof inputClasses.variant)['active']['color'];
  disabled?: boolean;
  label?: string;
  error?: string;
  clearable?: boolean;
  onClearClick?: (event: React.MouseEvent) => void;
  labelClassName?: string;
  inputClassName?: string;
  helperClassName?: string;
  errorClassName?: string;
  helperText?: React.ReactNode;
}

const Quantity = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      className,
      variant = 'outline',
      size = 'DEFAULT',
      rounded = 'DEFAULT',
      color = 'DEFAULT',
      disabled,
      label,
      error,
      clearable,
      onClearClick,
      labelClassName,
      inputClassName,
      errorClassName,
      helperClassName,
      helperText,
      ...inputProps
    },
    ref
  ) => {
    const variantStyle = inputClasses.variant[variant];
    return (
      <div className=' min-w-44'>
        <div className="relative flex items-start max-w-[11rem]">
          <Button type="button" id="decrement-button"
            onClick={()=>onChange(Number(value)-1)}
            className={clsx(
              buttonClasses.base,
              buttonClasses.size[size],
              buttonClasses.rounded_l[rounded]
            )}
            disabled={disabled}
          >
            <MinusIcon className='w-4 h-4' />
          </Button>
          <input type="number" 
            value={value}
            onChange={onChange}
            className={clsx(
              inputClasses.base,
              inputClasses.size[size],
              variantStyle.base,
              variantStyle.color[color],
              clearable && inputClearableClasses.base,
              error && inputClasses.error,
              inputClassName
            )}
            disabled={disabled}
            {...inputProps}
          />
          <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
            <span>{label}</span>
          </div>
          <Button type="button" id="increment-button"
            onClick={()=>onChange(Number(value)+1)}
            className={clsx(
              buttonClasses.base,
              buttonClasses.size[size],
              buttonClasses.rounded_r[rounded]
            )}
            disabled={disabled}
          >
            <PlusIcon className='w-4 h-4' />
          </Button>
        </div>
         {!error && helperText && (
          <FieldHelperText size={size} className={helperClassName}>
            {helperText}
          </FieldHelperText>
        )}
        {error && (
          <FieldError size={size} error={error} className={errorClassName} />
        )}
       </div>
    );
  }
);

Quantity.displayName = 'Quantity';
export default Quantity;
