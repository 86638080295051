import { Link } from "react-router-dom"
import { routes } from "../config/routes"
import Button from "./ui/button"

export default function Header(){
    return (
        <div className="shadow-md flex justify-between p-5">
            <div>
                JoinYourTrip Admin
            </div>
            <div className="flex gap-5">
            <Link className="hover:text-blue-500" to={routes.home}>
                Home
            </Link>
            <Link className="hover:text-blue-500" to={routes.tripRequests}>
                Trip Requests
            </Link>
            <Link className="hover:text-blue-500" to={routes.captainRequests}>
                Captain Requests
            </Link>
            <Link className="hover:text-blue-500" to={routes.cancelRequests}>
                Cancel Requests
            </Link>
            <Link className="hover:text-blue-500" to={routes.questions}>
                Trip Questions
            </Link>
            <Link className="hover:text-blue-500" to={routes.payouts}>
                Payouts
            </Link>
            </div>
            <div>
                {localStorage.getItem('password') && <Button onClick={()=>{localStorage.removeItem('password');window.location.reload()}}>Log Out</Button>}
            </div>
        </div>
    )
}