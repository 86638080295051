import { ArrowUpRightIcon } from "@heroicons/react/24/solid"
import Button from "./ui/button"
import { useModal } from "./modals/context"
import { useNavigate, useParams } from "react-router-dom"
import useApi from "../hooks/use-api"
import { useEffect, useState } from "react"
import { Api } from "../config/api-endpoints"

export default function Payout() {
    const { setModal } = useModal()

    const { tripId } = useParams()
    const { api } = useApi()
    const [payout, setPayout] = useState<any>({
        data: [],
        loading: true
    })

    async function loadPayout() {
        const res = await api.get(Api.getPayout(tripId!))
        if (res.status == 200) {
            console.log(res.data)
            setPayout({
                data: res.data,
                loading: false
            })
        } else {
            setPayout((prev: any) => ({
                ...prev,
                loading: false
            }))
        }
    }

    useEffect(() => {
        loadPayout()
    }, [])

    return (
        <>
            {payout.loading ? <div>loading...</div> :
                <div className="pb-20">
                    <div className="text-center text-2xl font-semibold mt-5">Payout Info</div>

                    <div className="grid grid-cols-2 gap-2 mt-5">
                        <div className="text-center p-2 bg-gray-200 rounded-md">Account Name: {payout.data.Captain.payment_info.account_name}</div>
                        <div className="text-center p-2 bg-gray-200 rounded-md">Account Number: {payout.data.Captain.payment_info.account_number}</div>
                        <div className="text-center p-2 bg-gray-200 rounded-md">IFSC Code: {payout.data.Captain.payment_info.ifsc_code}</div>
                        <div className="text-center p-2 bg-gray-200 rounded-md">Account Type: {payout.data.Captain.payment_info.account_type}</div>
                        <div className="text-center p-2 bg-gray-200 rounded-md">Paypal Id: {payout.data.Captain.payment_info.paypal_id}</div>
                    </div>

                    <div className="flex justify-center gap-7 my-16">
                        <Button variant="solid" onClick={() => setModal({ view: 'SUBMIT_PAYOUT', open: true, trip: payout.data })}>Submit</Button>
                    </div>

                    {/* Status */}
                    <div className="text-center text-xl font-semibold">Status</div>

                    <div className="flex justify-center gap-7">
                        <div>{`${payout.data.members}/${payout.data.number_of_tripmates.min} members`}</div>
                    </div>

                    {/* Captain Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">Captain Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/user/${payout.data.Captain.username}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Captain Profile
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <div className="flex justify-center gap-7">
                            <div>Phone:
                                <a href={`tel:${payout.data.Captain.phone}`} className="text-blue-500 hover:underline">{payout.data.Captain.phone}</a>
                            </div>
                            <div>Email:
                                <a href={`mailto:${payout.data.Captain.email}`} className="text-blue-500 hover:underline">{payout.data.Captain.email}</a>
                            </div>
                        </div>
                    </div>


                    {/* Trip Info */}
                    <div className="mt-10">
                        <div className="text-center text-xl font-semibold">Trip Info</div>
                        <div className="flex justify-center">
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/trip/${payout.data.id}`} className="flex items-center gap-1 text-blue-500 hover:underline" target="blank">
                                View Trip
                                <ArrowUpRightIcon className="w-4 h-4" />
                            </a>
                        </div>
                        <table className="table-auto mx-auto">
                            <tr>
                                <td className="font-semibold">Title:</td>
                                <td>{payout.data.title}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Departure Date:</td>
                                <td>{new Date(payout.data.departure_date).toDateString()}</td>
                            </tr>
                        </table>
                    </div>

                </div>
            }
        </>
    )
}